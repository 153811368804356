
<style lang="less" scoped>
.t_time_arrange {
  box-shadow: @shadow;
  background: #fff;
  .stu-module-header {
    border-bottom: 1px solid @borderBottomColor;
    padding: 20px;
  }
  .content {
    padding: 20px;
    .filter {
      margin-bottom: 20px;
      .el-select {
        width: 200px;
        margin: 0 10px;
      }
    }
    .timeline-title {
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
}
</style>
<template>
  <div class="t_time_arrange">
    <div class="stu-module-header">
      <div class="stu-module-title">时间安排</div>
    </div>
    <div class="content">
      <div class="filter">
        <span>毕业批次</span>
        <el-select v-model="filter" size="medium" :clearable="true">
          <el-option
            v-for="(item, index) in filterOptions"
            :key="index"
            :label="item.batchName"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-button @click="query" type="primary">查询</el-button>
      </div>
      <el-timeline>
        <el-timeline-item placement="bottom">
          <el-card>
            <h4 class="timeline-title">毕业批次</h4>
            <p>{{ batchName }}</p>
          </el-card>
        </el-timeline-item>
        <el-timeline-item
          v-for="(item, index) in data"
          :key="index"
          :timestamp="item.date"
          placement="top"
        >
          <el-card>
            <h4 class="timeline-title">
              {{ item.title }}
            </h4>
            <p>
              开始时间：{{ item.startTime }} &nbsp;&nbsp;&nbsp; 结束时间：{{
                item.endTime
              }}
            </p>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </div>
  </div>
</template>

<script>
export default {
  name: "t_time_arranges",
  data() {
    return {
      batchName: null,
      filter: null,
      filterOptions:[],
      data: [
      ],
      courseId:""
    };
  },
  activated() {
    this.selectionInit();
  },
  computed: {
    course() {
      return this.$store.getters.getTCourse;
    },
  },
  methods: {
    init() {
    },
    selectionInit(){
      this.openLoadingView();
      this.$axios_supermallData.get("/hnjxjy-core/teacherCourse/queryEduGraduateWorkBatch").then((resp)=>{
        if (resp.data.code == 2000) {
          this.filterOptions = resp.data.data;
          this.loadingView.close();
        }else {
          this.loadingView.close();
        }
      }).catch((e)=>{
        this.$message.error(e);
        this.loadingView.close();
      })
    },
    query() {
      if (this.filter == null || this.filter == ""){
        return this.$message.error("请选择毕业批次")
      }
      this.openLoadingView();
      this.$axios_supermallData.get("/hnjxjy-core/eduGraduateWorkBatch/queryEduGraduateWorkBatchById?id=" + this.filter)
          .then((resp) => {
            if (resp.data.code == 2000) {
              let data = resp.data.data;
              this.batchName = data.batchName;
              if (data.workStartTime != null && data.workEndTime){
                let project = {
                  title: "毕业大作业时间",
                  date: data.workStartTime,
                  startTime: data.workStartTime,
                  endTime: data.workEndTime,
                }
                this.data.push(project);
              }
              if (data.freeStartTime != null && data.freeEndTime){
                let question = {
                  title: "免试时间",
                  date: data.freeStartTime,
                  startTime: data.freeStartTime,
                  endTime: data.freeEndTime,
                }
                this.data.push(question);
              }
              if (data.writeStartTime != null && data.writeEndTime){
                let paper = {
                  title: "撰写大作业时间",
                  date: data.writeStartTime,
                  startTime: data.writeStartTime,
                  endTime: data.writeEndTime,
                }
                this.data.push(paper);
              }
              if (data.scoreStartTime != null && data.scoreEndTime){
                let free = {
                  title: "分数上报时间",
                  date: data.scoreStartTime,
                  startTime: data.scoreStartTime,
                  endTime: data.scoreEndTime ,
                }
                this.data.push(free);
              }
              this.loadingView.close();
            }else {
              this.loadingView.close();
            }
          }).catch((e)=>{
            this.$message.error(e);
            this.loadingView.close();
          })
    },
  },
};
</script>
